@import "./bower/bootstrap/less/bootstrap";
@import "./bower/fontawesome/less/font-awesome";
@import (reference) "variables";

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.ir br { display: none; }
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }


.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 15px !important; }
.mt-2 { margin-top: 30px !important; }
.mt-3 { margin-top: 45px !important; }
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 15px !important; }
.mb-2 { margin-bottom: 30px !important; }
.mb-3 { margin-bottom: 45px !important; }
.mx-0 { margin-left: 0 !important; margin-right: 0 !important; }
.mx-auto { margin-left: auto !important; margin-right: auto !important; }
.my-auto { margin-top: auto !important; margin-bottom: auto !important; }
.m-auto { margin: auto !important; }

.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: 15px !important; }
.pt-2 { padding-top: 30px !important; }
.pt-3 { padding-top: 45px !important; }
.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: 15px !important; }
.pb-2 { padding-bottom: 30px !important; }
.pb-3 { padding-bottom: 45px !important; }
.pr-1 { padding-right: 	15px !important; }
.px-0 { padding-bottom: 0; }
.px-1 { padding-left: 15px !important; padding-right: 15px !important; }
.px-2 { padding-left: 30px !important; padding-right: 30px !important; }
.px-3 { padding-left: 45px !important; padding-right: 45px !important; }
.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: 15px !important; padding-right: 15px !important; }
.px-2 { padding-left: 30px !important; padding-right: 30px !important; }
.px-3 { padding-left: 45px !important; padding-right: 45px !important; }

.w-100 { width: 100%; }

@media (min-width: 1280px) {
	.mt-0 { margin-top: 0 !important; }
	.mt-1 { margin-top: @line-height-computed * 0.75px !important; }
	.mt-2 { margin-top: @line-height-computed * 2px !important; }
	.mt-3 { margin-top: @line-height-computed * 3px !important; }
	.mb-0 { margin-bottom: 0 !important; }
	.mb-1 { margin-bottom: @line-height-computed * 0.75px !important; }
	.mb-2 { margin-bottom: @line-height-computed * 2px !important; }
	.mb-3 { margin-bottom: @line-height-computed * 3px !important; }

	.pt-0 { padding-top: 0 !important; }
	.pt-1 { padding-top: @line-height-computed * 0.75px !important; }
	.pt-2 { padding-top: @line-height-computed * 2px !important; }
	.pt-3 { padding-top: @line-height-computed * 3px !important; }
	.pb-0 { padding-bottom: 0 !important; }
	.pb-1 { padding-bottom: @line-height-computed * 0.75px !important; }
	.pb-2 { padding-bottom: @line-height-computed * 2px !important; }
	.pb-3 { padding-bottom: @line-height-computed * 3px !important; }
	.pr-1 { padding-right:  @line-height-computed * 0.75px !important; }
	.px-0 { padding-bottom: 0; }
	.px-1 { padding-left: @line-height-computed * 0.75px !important; padding-right: @line-height-computed * 0.75px !important; }
	.px-2 { padding-left: @line-height-computed * 2px !important; padding-right: @line-height-computed * 2px !important; }
	.px-3 { padding-left: @line-height-computed * 3px !important; padding-right: @line-height-computed * 3px !important; }
	.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
	.px-1 { padding-left: @line-height-computed * 0.75px !important; padding-right: @line-height-computed * 0.75px !important; }
	.px-2 { padding-left: @line-height-computed * 2px !important; padding-right: @line-height-computed * 2px !important; }
	.px-3 { padding-left: @line-height-computed * 3px !important; padding-right: @line-height-computed * 3px !important; }

	.pr-md-1 { padding-right: @line-height-computed * 1px !important; }
	.pr-md-2 { padding-right: @line-height-computed * 2px !important; }
	.pr-md-3 { padding-right: @line-height-computed * 3px !important; }
}

.bg-light {
	background-color: #F6FBFE;
}

address {
	display: block;
	background-color: #F6FBFE;
	margin: 0 auto -150px;
	padding: 45px;
	width: 720px;
	max-width: 85%;
	position: relative;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 8px;
	text-align: center;
}

.mb-cus {
	margin-bottom: 1.5em;
}
.livingstone {
	&-blue,
	&-primary {
		color: @brand-primary!important;
		a {
			color: @brand-primary!important;
		}
	}
	&-secondary {
		color: @brand-secondary!important;
		a {
			color: @brand-secondary!important;
		}
	}
}

.social {
	&-linkedin {
		color: @brand-linkedin!important;
	}
	&-twitter {
		color: @brand-twitter!important;
	}
	&-instagram {
		color: @brand-instagram!important;
	}
	&-facebook {
		color: @brand-facebook!important;
	}
}

// Floats
.float-right {
	float: right;
	margin: 0 0 @line-height-computed @line-height-computed;
	clear: right; // This ensures images don't 'bump' into each other
}

.float-left {
	float: left;
	margin: 0 @line-height-computed @line-height-computed 0;
	clear: left; // This ensures images don't 'bump' into each other
}

.float-center {
	float: none;
    margin: 0 auto;
}

.float-none {
	float: none; // if anyone knows why these are "important", let me know
	margin: 0;
}

// Useful for image link hover states
.transparent (@filter-opacity, @opacity) {
	zoom: 1;
	filter: alpha(opacity=@filter-opacity);
	opacity: @opacity;
}

/**	----------------------------------------
/**	Environment Indicator
/**	----------------------------------------*/
.env-indicator {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 99;
	color: #fff;
	border-radius: 5px 0 0 0;
	padding: 2px 5px;
	font-size: 11px;
}

.env-indicator.env-dev {
	background: @state-success-text;
}

.env-indicator.env-staging {
	background: @state-warning-text;
}


// Grids with 1px borders between them, Ryan loves using these.
.col-bdr, .col-bdl, .col-bdt, .col-bdb { position: relative;}
.col-bdr:after,
.col-bdl:before,
.col-bdt:before,
.col-bdb:after {
  content:"";
  position: absolute;
  line-height: 1px;
  border:0px solid #d5d5d5;
}

.col-bdr:after,
.col-bdl:before  {
  height: 100%;
}

.col-bdb:after,
.col-bdt:before  {
  width: 100%;
}

.col-bdr:after {
  right: 0px;
  top: 0px;
  border-right-width: 1px;
}

.col-bdl:before {
  left: 0px;
  border-left-width: 1px;
}

.col-bdb:after {
  bottom: 0px;
  border-bottom-width: 1px;
}

.col-bdt:before {
  top: 0px;
  border-top-width: 1px;
}

.btn-edit-this {
	.btn;
	.btn-default;
	.glyphicon;
	.glyphicon-pencil;
	padding: 5px 6px;
}

/**	----------------------------------------
/** STRUCTURE
/**	1. Layout & Structure
/**	2. Modular components
/**	3. Page Specific
/**	----------------------------------------

/**	----------------------------------------
/**	3. LAYOUT & STRUCTURE
/** Page structures & layout
/**	----------------------------------------*/
html, body {
	height: 100%;
	position: relative;
}

body {
	-webkit-font-smoothing: antialiased;
	// font-size: @font-size-base;
	// color: @text-color;
}

#wrap {
	min-height: 100%;
	height: auto;
	/* Negative indent footer by its height */
	margin: 0 auto;
	/* Pad bottom by footer height */
	padding: 0 0;
}

.container {
	max-width: 100%;
	clear: both;
}

#header {
	// margin-bottom: 15px;
	background: @white;
	position: sticky;
	top: 0;
	z-index: 9999;
	border-bottom: 1px solid @brand-secondary;
}

#header-logo {
	display: block;
	padding: 0;
	z-index: 999;
	width: 203px;
	height: 79px;
}

#footer {
	border-top: 3px solid @link-color;
	background: #ebebeb;
	margin-top: 0px;
	width: 100%;
	font-size: 80%;

	.footer-whois {
		text-align: center;
		margin: 18px 0;
		.footer-logo {
			margin-bottom: 4px;
		}
		.footer-title {
			color: @link-color;
			text-transform: uppercase;
			font-weight: bold;
			margin: 10px 0 0 0;
		}
		a {
			text-decoration: underline;
			color: #363636;
		}
	}
}


// Main menu
.navbar-default {
	margin: 0;
	// margin-top: 15px;
	// text-transform: uppercase;
	.dropdown-menu {
		padding: 0;
		a {
			padding: 10px 25px;
		}
	}
	.navbar-header {
		display: flex;
		justify-content: space-between;
		padding: 15px 0;
		.navbar-toggle {
			border-radius: 0;
			float: none;
			margin: auto 0 auto auto;
			order: 2;
		}
	}
	.navbar-header, .navbar-collapse{
		width: 100%;
	}
	.navbar-collapse {
		margin-left: auto;
	}
	.nav {
		li a.active span, >li a:hover span , .open > a , .open > a > span {
			color: @link-color;
		}
	}
	.nav > li > a {
		padding: 10px 25px;		
	}
	.dropdown-menu a span {
		// font-size: 13px;
	}
}

.btn-group {
	.dropdown-menu {
		min-width: 100%;
		max-width: 213px;
		padding: 0;
		a {
			white-space: normal;
			// font-size: 14px;
			font-weight: 700;
			padding: 8px 15px;
			&:hover {
				background-color: @brand-secondary;
				color: @white;
			}
		}
	}
}

/* ========================================= */

/**	----------------------------------------
/**	2. Modular components
/** Modular blocks & re-usable components
/**	----------------------------------------*/

// Media Cntd.
// ------------------------------------
.media {
	border-bottom: 1px solid @gray-lighter;
	&:last-child {
		border-bottom: 0;
	}
}

// Metadata at the bottom of an article/page
// ------------------------------------
.meta {
	display: block;
	font-size: 85%;
	border-top: 1px solid @gray-lighter;
	border-top-color: #efefef;
	border-bottom: 1px solid @gray-lighter;

	ul {
		margin: 0;
		list-style: none;
		float: right;
	}
	li {
		float: left;
		text-align: right;
		margin: 5px 0 5px 0;
		padding: 0 2px;
	}

	.cat {
		margin-top: 11px;

		&:before {
			content: '';
			.glyphicon;
			.glyphicon-tag;
			display: inline-block;
			margin-right: 5px;
		}
	}
}

.social-share-list {
  	.list-inline();

	.social-share {
		.btn();
		.btn-default();
		&.facebook { color: @brand-facebook; &:hover { color: darken(@brand-facebook, 8%); } }
		&.twitter { color: @brand-twitter; &:hover { color: darken(@brand-twitter, 8%); } }
		&.google { color: @brand-google; &:hover { color: darken(@brand-google, 8%); } }
		&.linkedin { color: @brand-linkedin; &:hover { color: darken(@brand-linkedin, 8%); } }
		&.pinterest { color: @brand-pinterest; &:hover { color: darken(@brand-pinterest, 8%); } }
		&.instagram { color: @brand-instagram; &:hover { color: darken(@brand-instagram, 8%); } }
		.share-total {
			margin-left: 5px;
			color: #000;
		}
	}
	.social-share-stacked {
		&.facebook { .fa-circle { color: @brand-facebook; } &:hover .fa-circle { color: darken(@brand-facebook, 8%); } }
		&.twitter { .fa-circle { color: @brand-twitter; } &:hover .fa-circle { color: darken(@brand-twitter, 8%); } }
		&.google { .fa-circle { color: @brand-google; } &:hover .fa-circle { color: darken(@brand-google, 8%); } }
		&.linkedin { .fa-circle { color: @brand-linkedin; } &:hover .fa-circle { color: darken(@brand-linkedin, 8%); } }
		&.pinterest { .fa-circle { color: @brand-pinterest; } &:hover .fa-circle { color: darken(@brand-pinterest, 8%); } }
		&.instagram { .fa-circle { color: @brand-instagram; } &:hover .fa-circle { color: darken(@brand-instagram, 8%); } }
		.share-total {
			margin-left: 5px;
			color: #000;
		}
  	}
  	margin-bottom: 0px;
}

// #breadcrumb {	
// 	.breadcrumb {
// 		margin-top: 20px;
// 		padding: 0;
// 		background-color: transparent;

// 		&:last-child {
// 			margin-bottom: 0;
// 		}
// 	}
// }

#breadcrumb {
	padding: 10px 15px;
	background-color: #F6FBFE;
	.breadcrumb {
		margin: auto;
		background-color: transparent;
		font-size: .75em;
	}
}

h1, h2, h3, h4, h5, h6 {
	color: @headings-color;
	margin: 1.4em 0;
	display: block;
}
h1, .h1 {
	font-size: 3em;
}
h2, .h2 {
	font-size: 1.875em;
	text-align: center;
	margin-bottom: 2em;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

h3, .h3,
h2, .h2 {
	color: @brand-primary;
}

.media-heading {
	font-family: 'Lato', "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: @font-size-base * 1.067;
	margin-bottom: .5em;
	color: @brand-dark;
}

// h1:first-child {
// 	margin-top: 0;
// }

.page-heading {
	margin-bottom: @line-height-base * 2em;
	.title-box  {
		margin-bottom: @line-height-base * .5em;
	}
}

.title-box {
	border-bottom: 2px solid @brand-secondary;
	max-width: 100%;
	width: 800px;
	text-align: center;
	padding-bottom: 10px;
	margin: 0 auto 3em;
}


// Metadata at the top of an article/page ie. published date
// ------------------------------------
.media-meta {
	list-style: none;
	padding: 0;

	margin-bottom: @line-height-base;
	font-size: @font-size-small;
	color: @gray;

	.date {
		font-weight: bold;
	}

	li {
		display: block;
		float: left;
		padding-right: 20px;
	}
	.addthis_toolbox {
		width: 120%;
		.addthis_counter.addthis_bubble_style {
			width: 36px !important;
		}
	}
	.clearfix;
}

.clear-both {
	clear: both;
}

/* SEARCH BUTTONS */

#submit {
	background: url('@{img-path}/new-search-icon.png');
	width: 20px;
	height: 20px;
	border: none;
}

.media.listing {
	// border-bottom: 1px solid #b38a2e;
	border-bottom: 0;
	margin: 3em auto;
	overflow: hidden;
}
.listing_grid {
	li {
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

// Landing page framework
// -------------------------
.grid_item {
	&.border_top {
		margin-top: 0;
		padding-top: 9px;
		border-top: 1px solid #d5d5d5;
	}

	&.border_bottom {
		margin-bottom: 0;
		padding-bottom: 9px;
		border-bottom: 1px solid #d5d5d5;
	}

	&.border_left {
		border-left: 1px solid #d5d5d5;
		padding-left: 9px;
		margin-left: 0;
	}

	&.border_right {
		border-right: 1px solid #d5d5d5;
		margin-right: 0;
		padding-right: 9px;
	}

	&.bg_tint {
		.box {
			margin-bottom: 20px;
		}
	}
}

// Video container for inline videos
// -------------------------
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.row, .d-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	align-items: start;
	clear: both;
	// width: 100%;
	&.justify-center {
		justify-content: center;
	}
	&.align-center {
		align-items: center;
	}
}

// Articles
// -------------------------

.article {
	// Test baseline
	// background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAHklEQVQYlWP8//8/AzGAiShVowoJAUYGBgZJYhQCANiGAykLdyIpAAAAAElFTkSuQmCC);

	// .baseLineType(14px, 20px);
	.article-image img {
		// border: 5px solid #dddddd;
	}
	.heading-bottom, h2, .h2 {
		// border-bottom: 2px solid #b38a2e;
		// margin-bottom: 10px;
		text-align: center;
		margin-bottom: 2em;
	}
}

// Images
// -------------------------

.article-image {
  padding:0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
  margin-top:4px; // adjust to ensure top of image in line with top of text

	.caption {
		margin-top: 5px;
		font-weight: bold;
		display: block;
		margin-bottom: 10px;

		font-size: @font-size-small;

		p {
			margin-bottom: 0;
			line-height: 13px;
		}
	}
}

.image-group,
.image-group-grid {
	a, img {
		.mx-auto;
	}
}

.rounded-circle {
	border-radius: 100%;
	overflow: hidden;
}
// any article images mobile (xs) do not have floats and scale 100%)

@media (max-width: @screen-xs-max) {

	.article-image {
		&.float-left {float:none; margin:0}
		&.float-right {float:none; margin:0}
	}

	.image-group-grid {
		.img {
			float:none;
			margin:0;
			padding:0 !important;
		}
	}
}


// Responsive Video in Jquery Cycle

.cycle-sentinel iframe {
  opacity: 0;
}

// Added as part of responsive video work
// Don't think it should apply generally

.cycle-slideshow {
	
	&.video-slideshow {
		max-width: 770px;
		margin: auto;

		> div { width: 100%; height: 100% }
		> img { width: 100%; height: 100% }
		iframe,object, embed { width: 100%; height: 100% }
	}
	
}

// Read more buttons
// -------------------------
.readmore {
	.label;
	font-size: @font-size-small;
	background: @gray-dark;
	padding: 2px 3px;

	&:hover {
		background-color: darken(@gray-dark, 10%);
		text-decoration: none;
		color: white;
	}

	// Reverse out if on dark colour
	&.inverse {
		background: #fff;
		color: @gray-dark;
	}

	&.inverse:hover {
		color: @gray-darker;
	}
}

.file-download {
	margin: 0 !important;
	li {
		width: 180px;
		margin-right: 20px;
		margin-bottom: 20px;
		text-align: center;
		background: @gray-light;
		border: 1px solid #CCC;
		padding: 5px;

		img {
			margin: 0 auto;
			display: block;
		}
		.pdf-title {
			// font-size: 14px;
			font-family: @headings-font-family;
			font-weight: 700;

			.pdf-icon {
				background: url('@{img-path}/pdf-icon.png');
				width: 15px;
				height: 20px;
				margin-right: 5px;
				display: inline-block;
				margin-bottom: -3px;
			}
		}
	}
}


// Pull quotes

.panel.pullquote {
  .quote {
	font-weight:bold;
	display:block;
  }
  .attribution {
	font-style:oblique;
	display:block;
  }

}

// Site Map
// ----------------------
.site-map {
	.site-map-list {
		li {
			width: 80px;
			margin-right: 20px;
			font-size: 20px;
			a {
				color: @text-color;
			}
		}
		li.last_child {
			margin-right: 0;
		}
	}
	.site-map-list-secondary {
		li {
			margin-top: 10px;
			font-size: 16px;
			a {
				color: @gray;
			}
		}
	}
}

/**	----------------------------------------
/**	3. PAGE SPECIFIC
/** Non-modular, non-repeatable elements
/**	----------------------------------------*/

.choices {

	> div:nth-child(odd) {
		background-color: #EFEFEF !important;
	}

	> div:nth-child(even) {
		background-color: #80CCCC !important;
	}

}

// slick slider
.slick-prev:before, .slick-next:before {
  color: #000;
}

.slick-list {
	overflow: hidden;
	z-index: 999;
}

.slider-nav .media-object {
  padding:0 @grid-gutter-width/6;
}


.gallery {
	.caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		color: #fff;
		width: 100%;
	  	padding: 0 10px;
	}
}

.glyphicon {
	.glyphicon;
}
.glyphicon-list {
	.glyphicon-list;
}

// Ensure we have reasonably tall pages no matter what.

#content {
	margin-bottom: 3.5em;
}

.contact #content {
	margin-bottom: 0;
	address + p {
		margin-bottom: 0;
		line-height: 0;
	}
}

.no-margin {
	margin: 0 !important;
}

.panel-default {
	.panel-body {
		padding: 0;
		.nav li {
			margin: 0;
			a {
				padding: 10px 16px;
				border-bottom: 1px dotted #dddddd;
			}
		}
		.nav li:last-child a {
			border: none;
		}
	}
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

.slide-banner {
	display: none;
}
.overlay-box {
	position: relative;
	display: block;
	margin-bottom: 30px;
	.overlay-content {
		background: rgba(0,0,0,0.8);
		width: 100%;
		position: absolute;
		bottom: 0;
		padding: 10px 15px; 
		.overlay-title {
			color: @white;
			margin: 0;
			font-size: 3rem;
		}
	}
	&:hover {
		.opacity(0.8);
	}
}

.btn-primary {
	text-transform: uppercase;
	padding: 10px 80px;
	font-size: @font-size-base;
	line-height: 1;
}

.btn-dark {
	padding: 1em 4.5em;
	border-radius: 4.5rem;
	background-color: @brand-dark;
	border: 2px solid @brand-secondary;
	color: #fff;
	line-height: 1;
	&:hover {
		background-color: #000;
		border: 2px solid @brand-secondary;
		color: #fff;
	}
}

.testimonial.slick-slider {
	margin: 0;
	p {
		margin: 0;
		// color: #000000;
	}
	.quote {
		margin-bottom: 34px;
	}
	.attribution {
		font-style: italic;
		font-size: 15px;
	}
	.company {
		color: @link-color;
	}
}

.page-banner {
	// display: none;
	min-height: 200px;
	&-overlay {
		display: none;
		margin-top: auto;
	}
}
.quote-content {
	font-size: @font-size-base;
	padding: 20% 15px 30px !important;
	margin: auto 0 0 auto;
	max-width: 100%;
	// width: 500px;
	// white-space: nowrap;
	p {
		color: white;
		margin: .33em 0;
		padding: 10px .85em;
		border-radius: .85em;
		&:first-of-type {
			border-bottom-right-radius: 0;
		}
		&:nth-of-type(n+2) {
			border-bottom-right-radius: 0;
			border-top-left-radius: 0;
		}
		&:nth-of-type(odd) {
			background-color: @brand-secondary;
		}
		&:nth-of-type(even) {
			background-color: @brand-primary;
		}
		&:last-of-type {
			border-bottom-right-radius: .85em;
		}
	}
}
.image-group-grid .img {
	margin-bottom: 20px;
}
.image-group-grid .caption {
	flex-grow: 1;
}

@media (min-width: @screen-xs) {
	.quote-content {
		font-size: 22px;
		max-width: 100%;
		p {
			padding: 10px 1em;
		}
	}
}

@media (max-width: 700px) {
	.overlay-wrap {
		width: 420px;
		left: 50%;
		transform: translateX(-50%);
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm) {
	.navbar-default {
		.navbar-header {
			width: auto;
			text-align: center;
			margin: auto;
		} 
		.navbar-collapse {
			display: flex !important;
			justify-content: space-between;
			.mx-auto;
		}
	}
	
	.navbar-collapse,
	.navbar-collapse.collapse {
		// display: flex !important;
		// justify-content: space-between;
		.nav.navbar-nav:first-of-type {
			margin-left: auto;
			margin-right: auto;
			li {
				margin: auto 2rem;
			}
		}
		.nav.navbar-nav:last-of-type {
			margin-left: auto;
		}
	}
	.title-box {
		h2 {
			font-size: @font-size-base * 3;
		}
	}
	.container {
		width: 1280px;
	}
	.slide-banner {
		display: block;
		color: @white;
		margin: 0;
		h1 {
			color: @white;
		}
		.slide {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: 1;
			}
		}
		.container {
			position: relative;
			z-index: 1;
		}
		.slide-content {
			display: table;
			width: 100%;
			// max-width: 360px;
			min-height: 550px;
	   		.slide-centering {
	   			display: table-cell;
				text-align: center;
				vertical-align: middle;
	   		}
	   		.slide-title {
	   			font-size: 5em;
	   			// text-transform: uppercase;
				// margin-bottom: 22px;
				margin: 1rem auto;
				p {
					margin-bottom: 0;
					&:nth-of-type(n+2) {
						font-size: 66.67%;
						// text-transform: lowercase;
						// font-weight: normal;
						margin-top: 0;
					}
				}
	   		}
	   		.slide-text {
				font-size: 1.75em;
				margin: .33em auto 1.5em;
	   			//  text-shadow: -1px 0px 0px #000000;
	   		}
		}
	}
	.navbar-default {
		span {
			font-weight: bold;
			color: #464646;
		}
	}
	#header-logo {
		margin-bottom: 0;
	}
	.nav > li > a {
		padding: 10px 18px;		
	}
	.page-banner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		// min-height: 500px;
		position: relative;
		&-overlay {
			display: block;
			max-width: 33%;
		}
		.slide-content {
			position: relative;
			// top: 50%;
			// -webkit-transform: translateY(-50%);
			// -ms-transform: translateY(-50%);
			// transform: translateY(-50%);
			margin: auto 6.67% 30px auto;
			display: inline-flex;
			// max-width: 375px;
			.quote-content {
				// font-size: 26px;
				max-width: none;
				padding: 0;
				width: auto;
				p {
					color: white;
					margin: .33em 0;
					padding: 14px 1em;
					border-radius: 1em;
					&:first-of-type {
						border-bottom-right-radius: 0;
					}
					&:nth-of-type(n+2) {
						border-bottom-right-radius: 0;
						border-top-left-radius: 0;
					}
					&:nth-of-type(odd) {
						background-color: @brand-secondary;
					}
					&:nth-of-type(even) {
						background-color: @brand-primary;
					}
					&:last-of-type {
						border-bottom-right-radius: 1em;
					}
				}
			}
			.quote-attribution {
				font-size: 16px;
				color: #5a5a5a;
			}
		}
	}
	.image-group,
	.image-group-grid {
		a, img {
			margin: 0 !important;
		}
		.caption {
			.px-2;
		}
	}
}

@media (min-width: 900px) {
	.navbar-default {
		.navbar-header {
			width: auto;
			text-align: left;
			margin-left: 0 !important;
		} 
		.navbar-collapse {
			.my-auto;
			width: auto;
			margin-right: 0 !important;
		}
	}
	.quote-content {
		font-size: 24px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md) {
	.navbar-default {
		.navbar-header {
			width: auto;
			text-align: left;
			margin-left: 0 !important;
		} 
		.navbar-collapse {
			.my-auto;
			width: auto;
			margin-right: 0 !important;
		}
	}
	.nav > li > a {
		padding: 10px 25px;
	}
	.page-banner {
		// height: 450px;
		&-overlay {
			// max-width: 33.33%;
		}
		.slide-content {
			max-width: none;
			.quote-content {
				font-size: 30px;
				// max-width: none;
				// margin-bottom: 30px;
			}
		}
	}
}

@media (min-width: 1024px) {
	.quote-content {
		font-size: 26px;
	}
}

/* Large devices (large desktops, 1200px and up) */
// @media (min-width: @screen-lg) {
// 	.page-banner {
// 		.slide-content {
// 			.quote-content {
// 				font-size: 30px;
// 			}
// 		}
// 	}
// }

// @media (min-width: 1400px) {
// 	.page-banner {
// 		.slide-content {
// 			.quote-content {
// 				font-size: 32px;
// 			}
// 		}
// 	}
// }
.media-body {
	width: auto;
}